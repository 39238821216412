import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const MeYouOldieGoldie = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Me & You & Oldie & Goldie" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Me & You & Oldie & Goldie</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Dec. 31, 2022) </p>
        </div>
        <div className="articleBody">
          <p>The mythology, like the corporation, simply must grow.</p>
          <p>I. Oldie & Goldie; or, An Eternal Rivalry</p>
          <p>
            Oldie sits alone in a booth at a diner. Sunlight shines across his
            table through the large glass windows. His bushy white hair and
            beard contrast his aged and weathered dark brown face. He’s dressed
            modestly in a long-sleeve polo, slacks, and loafers. He takes a sip
            from his mug, gazing sternly through the window.
          </p>
          <p>
            His gaze is broken — Goldie enters the diner and sits across from
            him in the booth. They’re dressed similarly, and their physical
            features are identical. The only way to tell them apart is the
            glistening gold hoop earring that dangles from Goldie’s left ear.
          </p>
          <p>
            Goldie pulls some documents from a leather folio and places them in
            front of Oldie. Oldie gives the documents a quick glance, and then
            locks eyes intensely with his brother.
          </p>
          <p>
            From afar these two may look like the uncles who still call people
            “jive turkies” while grilling at the cookout, but there’s much more
            to them than meets the eye…
          </p>
          <p>
            For eons, the beings we now refer to as Oldie and Goldie have served
            as the Stewards of the Opposing Forces, and you’re undoubtedly
            familiar with their presence across time, space, and cultures: Cain
            & Abel, Osiris & Set, Romulus & Remus, Shaka & Dingane, Goku &
            Raditz, Eli & Peyton, and countless others have channeled the
            fraternal malice of Oldie & Goldie. Of course their personal
            rivalries pale in comparison to their foremost responsibilities as
            Stewards: Oldie serves{" "}
            <span className="editorsNote">
              the one who bathes in the light of the Borealis and becomes a
              trillionaire
            </span>
            , in other words me, and Goldie serves{" "}
            <span className="editorsNote">
              the one who eats of the flesh of the mushroom and becomes the
              People’s Champion
            </span>
            , in other words{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/shoya-borealis/"
              target="__blank"
              rel="noopener noreferrer"
            >
              You
            </a>
            .
          </p>
          <p>Back in the diner, Oldie maintains his suspicious gaze.</p>
          <p>
            OLDIE: Why so generous? <br />
            GOLDIE: Just tryna keep the peace. Sooner or later you woulda found
            out. <br />
            OLDIE: Found out what? <br />
            GOLDIE: ……he found the Fa.
          </p>
          <p>
            There’s a{" "}
            <a
              href="https://youtu.be/_eO_5q5dR9M?t=19"
              target="__blank"
              rel="noopener noreferrer"
            >
              dolly zoom
            </a>{" "}
            on Oldie’s face — he tries to mask the fact that he’s mortified, but
            can’t help his eyes widening.
          </p>
          <p>Goldie unsheathes a fountain pen and places it on the table.</p>
          <p>
            GOLDIE: Just accept our terms, and everything can stay how it is.
          </p>
          <p>
            Oldie composes himself. He pushes the documents back across the
            table. He shakes his head to himself, his expression forlorn. He
            knows what must happen next. He rises and exits the diner.
          </p>
          <p>II. What Are Friends For?</p>
          <p>
            On{" "}
            <a
              href="https://shoya.co/shoyaright/vol1/mt-shoya"
              target="__blank"
              rel="noopener noreferrer"
            >
              Mt. Shoya
            </a>
            , I enter the lounge where my precious Shoyarights are hanging out.
            Bin Shoya and Shoya-Kun are having a dunk contest on NBA Live 2005
            on a 124” screen, the others are reading magazines or scrolling on
            their iPhone 17s.
          </p>
          <p>My expression is grim. I announce:</p>
          <p>ME: I have an important mission. But I have to go alone.</p>
          <p>No one looks up from what they’re doing.</p>
          <p>
            ME: I couldn’t risk putting any of you in danger, you mean too much
            to me.
          </p>
          <p>
            No one bats an eye. Shoya-Kun attempts a lob to himself into a 360
            windmill as LeBron James.
          </p>
          <p>
            ME: This could be the deadliest, most consequential battle of my
            life.
          </p>
          <p>Crickets.</p>
          <p>
            ME: NO!!! DON’T TRY TO STOP ME!!! I TOLD YOU I CAN’T LOSE YOU!!!
          </p>
          <p>I storm out, wiping a tear from my eye.</p>
          <p>III. Me & You; or, The Fate of the Fa</p>
          <p>
            Oldie and I arrive on horseback to a wide open battlefield. You and
            Goldie are on horseback half a league way. Oldie and Goldie ride to
            meet one another in the middle.
          </p>
          <p>
            OLDIE: Just hand it over. <br />
            GOLDIE: No.
          </p>
          <p>They each ride back, and deliver the message. I suck my teeth.</p>
          <p>
            ME: (shouting) JUST HAND IT OVER! <br />
            YOU: NO!
          </p>
          <p>
            Long ago, the Analysts projected that if I, Lil Shoyaright! aka Big
            C-Suite aka Corporation Man, should ever come to possess the Fa,
            then I would evolve……I would change……I would morph……into
            something……more unspeakably powerful……than my already unspeakable
            power……I would become……the{" "}
            <span className="editorsNote">FASHOYARIGHT!</span>
          </p>
          <p>
            The <span className="editorsNote">FASHOYARIGHT!</span> is my
            ultimate form, an ascended state of being in which I manifest the
            Divine Corporation — where my every exhale is a merger and my every
            inhale is an acquisition, where my sweat is crude oil and my farts
            contain more gas than the Nord Stream, where as Midas, every item I
            touch turns not to gold, but to an LLC. I also turn super brolic and
            grow angel wings and get all types of crazy other powers idk use
            your imagination…point being it would mean the end of all the poor
            unincorporated souls of this world.
          </p>
          <p>And You are the only thing standing in my way.</p>
          <p>
            On the battlefield, I put in a pair of earplugs, and pull out a
            strange flute. I play three imperceptibly high notes, You clutch
            your ears and grimace. I stop playing. You look confused, but
            undeterred.
          </p>
          <p>YOU: Cancelers! Advance!</p>
          <p>
            The ground begins to shake — an army of Generation Z’s five thousand
            most formidable warriors assembles behind You. They resemble the
            Roman legion, except armed with circular solar panel shields and
            electric spears. They halt and form a phalanx.
          </p>
          <p>
            Just then, the ground begins to shake again, and the sound of large
            wings flapping can be heard, as well as a faint hissing sound.
            Shortly after, a shadow is cast over the entire battlefield. You
            look up, and it dawns on you.
          </p>
          <p>YOU: No, it can’t be.</p>
          <p>
            The{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/crypto-butterfly"
              target="__blank"
              rel="noopener noreferrer"
            >
              Crypto Butterfly
            </a>{" "}
            descends to the east, the NFT Serpent slithers from the west, the
            Blockchain Rhino stampedes from the south right onto the
            battlefield!!! These three enormous, magnificent beasts with
            brilliant grey-green-purple skin stare down Gen Z's finest. I get
            down from my horse and leap 50 feet into the air and mount the
            Blockchain Rhino.
          </p>
          <p>
            Me and You lock eyes. An unlikely quiet prevails for a brief moment.
            Then….
          </p>
          <p>
            YOUR ARMY ROARS!!! MY BEASTS WAIL!!!! <br />
            AHHHHH!!!:LKJC:LVIKJHNSD:FOih;lKJSHDf;lkiJHSDG;ohsdSDFRWg!SDgadfg
          </p>
          <p>
            And okay yeah so what I lost big whoop lol see y’all next year Happy
            New Year love y’all!
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default MeYouOldieGoldie
